import { combineReducers } from 'redux';
import messages            from './messages';
import stateStore          from './stateStore';
import users               from './users';


const rootReducer = combineReducers({
	messages,
	stateStore,
	users,
});

export default rootReducer;
