import * as c from "../constants/Constants";

/* global LangJs */
const initialState = {
	loading: false,
	listLoading: true,
	listLoadingOld: false,
	user: {
		name: "",
		thumb: "",
		id: "",
		token: ""
	},
	input: "",
	inputState: c.INPUT_ENABLED,
	lastMessageTime: null
};

export default function stateStore(state = initialState, action) {
	switch (action.type) {
		case c.SET_USER:
			return Object.assign({}, state, { user: action.user });

		case c.ADD_SMILEY:
			return Object.assign({}, state, { input: `${state.input} ${action.text} ` });

		case c.WRITE:
			return Object.assign({}, state, { input: action.text });

		case c.INPUT_STATE:
			return Object.assign({}, state, { inputState: action.state });

		case c.LIST_LOADING:
			return Object.assign({}, state, { listLoading: false });

		case c.LIST_LOADING_OLD:
			return Object.assign({}, state, { listLoadingOld: action.state });

		case c.LAST_MSG_TIME:
			return Object.assign({}, state, { lastMessageTime: action.time });

		default:
			return state;
	}
}
