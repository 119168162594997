import React    from 'react';
import PropTypes from "prop-types"


/* global LangJs */
export default class BaseTemplate extends React.Component {

	static propTypes = {
		actions: PropTypes.object.isRequired,
		stateStore: PropTypes.object.isRequired,
	};

	state = {
		interval: 0,
	};

	componentDidMount() {
		this.props.actions.handleInit();
		this.props.actions.handleInitData();
		this.state.interval = setInterval(() => {
			if ((!document.hidden) && (!this.props.stateStore.loading)) {
				if (document.getElementById('shoutbox-wrapper').offsetParent !== null) {
					this.props.actions.handleBeat(this.props.stateStore.lastMessageTime);
				}
			}
		}, 5000);
	}

	componentWillUnmount() {
		clearInterval(this.state.interval);
	}

	render() {
		return null;
	}
}
