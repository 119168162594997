import React from "react"
import PropTypes from "prop-types"


export default class Thumb extends React.PureComponent {

	static propTypes = {
		user: PropTypes.object.isRequired,
	};

	render() {
		return (
			<div>
				<img
					src={this.props.user.thumb}
					width="40"
					height="40"
					className="user-thumb"
					title={this.props.user.name}
				/>
			</div>
		);
	}
}
