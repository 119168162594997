import React    from 'react';
import PropTypes from "prop-types"
import SmileyModal from './SmileyModal.jsx';

/* global LangJs */
export default class Smiley extends React.Component {

	static propTypes = {
		actions: PropTypes.object.isRequired,
	};

	state = {
		open: false,
	};

	handleClick = () => {
		this.setState({
			open: !this.state.open,
		});
	};

	render() {
		let btnClass = 'show-smiley';
		if (this.state.open) {
			btnClass += ' text-active';
		}
		return (
			<div>
				<a className={btnClass} onClick={this.handleClick} >
					<i className="fa fa-smile-o" />
				</a>
				<SmileyModal open={this.state.open} actions={this.props.actions} />
			</div>
		);
	}
}
