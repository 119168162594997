import React from "react";
import PropTypes from "prop-types"
import ReactDOM from "react-dom";
import * as c from "../../constants/Constants";

/* global LangJs */
export default class Input extends React.Component {
	static propTypes = {
		actions: PropTypes.object.isRequired,
		stateStore: PropTypes.object.isRequired,
		user: PropTypes.object.isRequired
	};

	constructor(props) {
		super(props);
		this.state = { text: "" };

		this.handleChange = this.handleChange.bind(this);
		this.handleKeyUp = this.handleKeyUp.bind(this);
		this.submit = this.submit.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.stateStore.input.length < this.props.stateStore.input.length) {
			ReactDOM.findDOMNode(this.refs.chatInput).focus();
		}
	}

	handleChange(event) {
		if (this.props.stateStore.inputState !== c.INPUT_WAITING) {
			this.props.actions.handleWrite(event.target.value);
		}
	}

	handleKeyUp(event) {
		if (event.key === "Enter") {
			this.submit();
		}
	}

	submit() {
		const text = this.props.stateStore.input.trim();
		if (text.length > 0) {
			this.props.actions.sendNew(text, this.props.user.token);
		}
	}

	render() {
		let icon = <i className="fa fa-paper-plane-o" onClick={this.submit} />;
		let wrapperClass = "chat-input";

		if (this.props.stateStore.inputState === c.INPUT_WAITING) {
			icon = <i className="fa fa-refresh fa-spin" />;
			wrapperClass += " loading";
		}

		if (this.props.stateStore.inputState === c.INPUT_FAIL) {
			icon = <i className="fa fa-exclamation-triangle text-danger" />;
		}

		return (
			<div className={wrapperClass}>
				<span className="username">{this.props.user.name}</span>
				<input
					ref="chatInput"
					type="text"
					placeholder={LangJs.chat.input}
					value={this.props.stateStore.input}
					onChange={this.handleChange}
					onKeyUp={this.handleKeyUp}
				/>
				{icon}
			</div>
		);
	}
}
