import React from 'react';
import PropTypes from "prop-types"
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Thumb from '../components/footer/Thumb.jsx';
import Input from '../components/footer/Input.jsx';
import Smiley from '../components/footer/Smiley.jsx';
import * as BaseActions from '../actions/index';


const Footer = ({ stateStore, usersStore, actions }) => (
	<footer className="clearfix">
		<Thumb user={stateStore.user} />
		<Input stateStore={stateStore} actions={actions} user={stateStore.user} />
		<Smiley users={usersStore} actions={actions} />
	</footer>
);

Footer.propTypes = {
	stateStore: PropTypes.object.isRequired,
	usersStore: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
	stateStore: state.stateStore,
	usersStore: state.users,
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(BaseActions, dispatch),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Footer);
