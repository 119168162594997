import React from 'react';
import PropTypes from "prop-types";


export default class UsersList extends React.PureComponent {

	static propTypes = {
		open: PropTypes.bool.isRequired,
		usersStore: PropTypes.bool.isRequired,
	};

	render() {
		let boxClass = 'userlist-modal';
		if (this.props.open) {
			boxClass += ' open';
		}
		return (
			<div className={boxClass} >
				{this.props.usersStore.map((val, key) => (
					<a href={val.link} title={val.name} key={key} target="_blank" >
						<img src={val.thumb} width="50" />
					</a>
				))}
			</div>
		);
	}
}
