import React    from 'react';
import PropTypes from "prop-types"
import EmojiPicker from 'emojione-picker';

/* global LangJs */
export default class SmileyModal extends React.Component {

	static propTypes = {
		open: PropTypes.bool.isRequired,
		actions: PropTypes.object.isRequired,
	};

	handleClick = (data) => {
		this.props.actions.addSmiley(data.shortname);
	};

	render() {
		let classBox = 'smiley-modal';
		if (this.props.open) {
			classBox += ' open';
		}

		return (
			<div className={classBox} >
				<EmojiPicker onChange={this.handleClick} />
			</div>
		);
	}
}
