import React from 'react';
import PropTypes from "prop-types"
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ListTemplate from '../components/List.jsx';
import * as BaseActions from '../actions/index';

const List = ({ messages, stateStore, actions }) => (
	<ListTemplate messages={messages} stateStore={stateStore} actions={actions} />
);

List.propTypes = {
	messages: PropTypes.array.isRequired,
	stateStore: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
	messages: state.messages,
	stateStore: state.stateStore,
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(BaseActions, dispatch),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(List);
