import React from "react";
import PropTypes from "prop-types"

import UsersList from "./UsersList.jsx";

/* global LangJs */
export default class Buttons extends React.Component {
	static propTypes = {
		stateStore: PropTypes.object.isRequired,
		usersStore: PropTypes.object.isRequired,
		actions: PropTypes.object.isRequired
	};

	state = {
		usersActive: false
	};

	handleClickUsers = () => {
		this.setState({
			usersActive: !this.state.usersActive
		});
	};

	handleRefresh = () => {
		if (!this.props.stateStore.loading) {
			this.props.actions.handleBeat(this.props.stateStore.lastMessageId);
		}
	};

	render() {
		let refreshClass = "fa fa-refresh";
		if (this.props.stateStore.loading) {
			refreshClass += " fa-spin";
		}

		let usersClass = "btn";
		if (this.state.usersActive) {
			usersClass += " text-active";
		}

		let reportLink = `/ajax-modal/video-report/?type=chat&time=${new Date().getTime()}`;

		return (
			<div>
				<div className={usersClass} title={LangJs.chat.btnUsers} onClick={this.handleClickUsers}>
					<i className="fa fa-users" />
				</div>
				<div className="btn hidden" title={LangJs.chat.btnRefresh} onClick={this.handleRefresh}>
					<i className={refreshClass} />
				</div>
				<div
					className="btn hidden"
					title={LangJs.chat.btnReport}
					data-js="show-report-modal"
					data-url={reportLink}
				>
					<i className="fa fa-flag" />
				</div>
				<UsersList usersStore={this.props.usersStore} open={this.state.usersActive} />
			</div>
		);
	}
}
