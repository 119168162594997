export const LOADING_START = "LOADING_START";
export const LOADING_STOP = "LOADING_STOP";
export const REFRESH_USERS = "REFRESH_USERS";
export const SET_USER = "SET_USER";
export const ADD_SMILEY = "ADD_SMILEY";
export const WRITE = "WRITE";
export const INPUT_STATE = "INPUT_STATE";
export const LOAD_NEW = "LOAD_NEW";
export const LIST_LOADING = "LIST_LOADING";
export const LIST_LOADING_OLD = "LIST_LOADING_OLD";
export const LAST_MSG_TIME = "LAST_MSG_TIME";
export const LOAD_OLD = "LOAD_OLD";
export const DELETE_ITEM = "DELETE_ITEM";
export const BAN_USER = "BAN_USER";

//* Custom *//
export const URL = {
	LOAD: "/shoutbox-load-new/?time=",
	INIT: "/shoutbox-init/",
	INIT_DATA: "/shoutbox-load-old/",
	LOAD_OLD: "/shoutbox-load-old/?time=",
	CREATE: "/shoutbox-create/",
	DELETE: "/shoutbox-delete/?id=",
	BAN: "/shoutbox-ban/?id="
};

export const INPUT_ENABLED = 1;
export const INPUT_WAITING = 2;
export const INPUT_FAIL = 3;
