import * as c from '../constants/Constants';

/* global LangJs */
const initialState = [];

export default function users(state = initialState, action) {
	switch (action.type) {
		case c.REFRESH_USERS:
			return action.users;

		default:
			return state;
	}
}
