import React, { Component } from "react";
import PropTypes from "prop-types";

import Autolinker from "autolinker";
import emojione from "emojione";

/* global LangJs Userdata */
export default class Item extends Component {
	static propTypes = {
		item: PropTypes.object.isRequired,
		actions: PropTypes.object.isRequired,
		userId: PropTypes.number,
		userName: PropTypes.string,
	};

	getText(msg) {
		let text = msg;
		if (text !== "") {
			try {
				text = window.atob(text);
			} finally {
				try {
					text = decodeURIComponent(unescape(text));

					// seznam našich domén
					const ourDomains = [
						"ashemaletube.com",
						"boyfriendtv.com",
						"pornoxo.com",
						"fetishpapa.com",
						"amagay.com",
						"ast.tv",
						"tranny.vip",
						"chicks.vip",
						"transen.xyz",
					];

					const ourSubdomains = ["", "www.", "m.", "forum."];

					// najdi linky
					text = Autolinker.link(text, {
						className: "linkified",
						replaceFn: function (match) {
							let matchHref = new URL(match.getAnchorHref());
							let isOurDomain = false;

							// pokud link obsahuje text naší domény
							ourDomains.forEach((domain) => {
								ourSubdomains.forEach((subdomain) => {
									if (matchHref.hostname == subdomain + domain) {
										isOurDomain = true;
									}
								});
							});

							// prolinkuj
							if (isOurDomain) {
								return true;
							}

							return false;
						},
					});
				} catch (e) {
					// console.log(e)
				}
			}
		}
		return text;
	}

	getCreated(time) {
		const timestamp = time * 1000;
		const date = new Date(timestamp);

		const dateFormatted =
			date.getFullYear() +
			"-" +
			("0" + (date.getMonth() + 1)).slice(-2) +
			"-" +
			("0" + date.getDate()).slice(-2) +
			" " +
			(date.getHours() < 10 ? "0" : "") +
			date.getHours() +
			":" +
			(date.getMinutes() < 10 ? "0" : "") +
			date.getMinutes();

		return {
			timestamp,
			timeago: $.fn.timeago.get(timestamp),
			full: dateFormatted,
		};
	}

	deleteItem = () => {
		this.props.actions.deleteItem(this.props.item.id);
	};

	banItem = () => {
		if (this.props.item.author.id == 0) {
			this.props.actions.banItem(this.props.item.id, this.props.item.author.name);
		} else {
			this.props.actions.banItem(this.props.item.id, this.props.item.author.id);
		}
	};

	render() {
		emojione.ascii = true;
		if (typeof this.props.item === "undefined") {
			return null;
		}

		const message = this.props.item;
		let wrapperClass = "text";
		let badge = [];
		let mediaList = null;
		let deleteLink = null;
		let banLink = null;
		let isAuthor = false;

		const created = this.getCreated(message.created);
		const text = emojione.toImage(this.getText(message.message));
		const test = text.replace(/<img [^>]*>/g, "").trim();
		if (test.length === 0) {
			wrapperClass += " smiley-only";
		}

		if (this.props.userId === 0) {
			if (message.author.name === this.props.userName) {
				isAuthor = true;
			}
		} else {
			if (message.author.id === this.props.userId) {
				isAuthor = true;
			}
		}

		if (isAuthor) {
			badge.push(
				<span className="usertitle self" key="1">
					{LangJs.chat.you}
				</span>,
			);
		}

		if (message.author.isAdmin) {
			badge.push(
				<span className="usertitle admin" key="2">
					{LangJs.chat.admin}
				</span>,
			);
		}

		if (message.videos.length > 0) {
			let content = "";
			message.videos.map((val) => {
				content += val;
				return true;
			});
			mediaList = <ul className="media-wrapper clearfix" dangerouslySetInnerHTML={{ __html: content }} />;
		}

		if (Userdata.admin) {
			deleteLink = (
				<span className="pull-right delete" onClick={this.deleteItem}>
					<i className="fa fa-trash text-danger" />
				</span>
			);
		}

		if (Userdata.admin) {
			banLink = (
				<span className="pull-right delete" title="ban" onClick={this.banItem}>
					<i className="fa fa-ban text-danger" />
				</span>
			);
		}

		return (
			<div className="item clearfix">
				<div className="pull-left">
					<a href={message.author.link} target="_blank">
						<img src={message.author.thumb} />
					</a>
				</div>
				<div className="pull-left">
					<div>
						<span>
							<a
								href={message.author.link}
								target="_blank"
								className={message.author.isVerified ? "username verified" : "username"}
							>
								{message.author.name}
							</a>
							{badge}
						</span>
						{deleteLink}
						{banLink}
						<span className="time" data-timeago={created.timestamp} title={created.full}>
							{created.timeago}
						</span>
					</div>
					<div className={wrapperClass}>
						<div dangerouslySetInnerHTML={{ __html: text }} />
						{mediaList}
					</div>
				</div>
			</div>
		);
	}
}
