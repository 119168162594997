import React from 'react';
import PropTypes from "prop-types";

/* global LangJs */
export default class OnlineCount extends React.PureComponent {

	static propTypes = {
		count: PropTypes.number.isRequired,
	};

	render() {
		return (
			<div>
				<span className="greendot" />
				<span className="header-text" >{this.props.count} {LangJs.chat.online}</span>
			</div>
		);
	}
}
