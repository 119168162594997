import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import OnlineCount from '../components/header/OnlineCount.jsx';
import Buttons     from '../components/header/Buttons.jsx';
import * as BaseActions from '../actions/index';

/* global Userdata */
const Header = ({ stateStore, usersStore, actions }) => (
	<header>
		<div className="pull-left" >
			<OnlineCount count={usersStore.length} />
		</div>
		<div className="pull-right" >
			<Buttons stateStore={stateStore} usersStore={usersStore} actions={actions} />
		</div>
	</header>
);

Header.propTypes = {
	stateStore: PropTypes.object.isRequired,
	usersStore: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
	stateStore: state.stateStore,
	usersStore: state.users,
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(BaseActions, dispatch),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Header);
