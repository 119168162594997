import React from 'react';
import PropTypes from "prop-types"
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as BaseActions from '../actions/index';
import BaseTemplate from '../components/Base.jsx';

/* global Userdata */
const BaseModule = ({ stateStore, actions }) => (
	<BaseTemplate stateStore={stateStore} actions={actions} />
);

BaseModule.propTypes = {
	stateStore: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
	stateStore: state.stateStore,
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(BaseActions, dispatch),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(BaseModule);
