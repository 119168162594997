import * as c from "../constants/Constants";

export const refreshRun = () => ({
	type: c.LOADING_START
});

export const refreshStop = () => ({
	type: c.LOADING_STOP
});

export const setUser = userData => ({
	type: c.SET_USER,
	user: userData
});

export const setUsers = usersList => ({
	type: c.REFRESH_USERS,
	users: usersList
});

export const addSmiley = text => ({
	type: c.ADD_SMILEY,
	text
});

export const handleWrite = text => ({
	type: c.WRITE,
	text
});

export const setInputState = state => ({
	type: c.INPUT_STATE,
	state
});

export const setNewMessages = messages => ({
	type: c.LOAD_NEW,
	messages
});

export const setOldMessages = messages => ({
	type: c.LOAD_OLD,
	messages
});

export const setLastTime = time => ({
	type: c.LAST_MSG_TIME,
	time
});

export const listLoadingStop = () => ({
	type: c.LIST_LOADING
});

export const listLoadingOld = state => ({
	type: c.LIST_LOADING_OLD,
	state
});

export const removeItemFromList = id => ({
	type: c.DELETE_ITEM,
	id
});

export const banUser = userId => ({
	type: c.BAN_USER,
	userId
});

function handleAjaxData(response, dispatch) {
	dispatch(refreshStop());
	dispatch(listLoadingStop());

	if (response.status) {
		dispatch(setUsers(response.data.users));
		dispatch(setNewMessages(response.data.data));
		if (response.data.data.length > 0) {
			dispatch(setLastTime(response.data.data[response.data.data.length - 1].created));
		}
	} else {
		// => set refresh error
	}
}

function getData(url, dispatch) {
	dispatch(refreshRun());
	$.ajax({
		url,
		dataType: "json",
		type: "GET",
		success: response => {
			handleAjaxData(response, dispatch);
		}
	});
}

export const handleInitData = () => dispatch => {
	getData(c.URL.INIT_DATA, dispatch);
};

export const handleBeat = lastTime => dispatch => {
	if (lastTime !== null) {
		getData(c.URL.LOAD + lastTime, dispatch);
	} else {
		dispatch(setLastTime(Math.floor(new Date().getTime() / 1000)));
	}
};

export const loadOld = timestamp => dispatch => {
	dispatch(listLoadingOld(true));
	$.ajax({
		url: c.URL.LOAD_OLD + timestamp,
		dataType: "json",
		type: "GET",
		success: response => {
			dispatch(listLoadingOld(false));

			if (response.status) {
				dispatch(setOldMessages(response.data.data));
			} else {
				// => set refresh error
			}
		}
	});
};

export const handleInit = () => dispatch => {
	$.ajax({
		url: c.URL.INIT,
		dataType: "json",
		type: "GET",
		success: response => {
			dispatch(setUser(response.data));
		}
	});
};

export const sendNew = (text, token) => dispatch => {
	dispatch(setInputState(c.INPUT_WAITING));

	$.ajax({
		url: c.URL.CREATE,
		type: "POST",
		data: {
			messageBox: text,
			token: token
		},
		success: response => {
			if (response.status) {
				dispatch(setInputState(c.INPUT_ENABLED));
				dispatch(setNewMessages(response.data));
			} else {
				dispatch(setInputState(c.INPUT_FAIL));
			}
			dispatch(handleWrite(""));
		}
	});
};

export const deleteItem = id => dispatch => {
	$.ajax({
		url: c.URL.DELETE + id,
		type: "GET",
		success: response => {
			if (response.status) {
				dispatch(removeItemFromList(id));
			}
		}
	});
};

export const banItem = (itemId, userId) => dispatch => {
	$.ajax({
		url: c.URL.BAN + itemId,
		type: "GET",
		success: response => {
			if (response.status) {
				dispatch(banUser(userId));
			}
		}
	});
};
