import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import Item from "./Item.jsx";

/* global LangJs */
export default class List extends Component {
	static propTypes = {
		messages: PropTypes.array.isRequired,
		actions: PropTypes.object.isRequired,
		stateStore: PropTypes.object.isRequired,
	};

	state = {
		timeout: 0,
		timeout2: 0,
	};

	handleScroll = () => {
		const wrapper = ReactDOM.findDOMNode(this);
		if (wrapper.scrollTop === 0) {
			clearTimeout(this.state.timeout2);
			this.state.timeout2 = setTimeout(this.loadOld, 700);
		}
	};

	loadOld = () => {
		const last = this.props.messages[0].created ? this.props.messages[0].created : null;
		if (last !== null) {
			this.props.actions.loadOld(last);
		}
	};

	scroll = () => {
		const wrapper = ReactDOM.findDOMNode(this);
		ReactDOM.findDOMNode(this).scrollTop = wrapper.scrollHeight;
	};

	render() {
		if (this.props.stateStore.listLoading) {
			return (
				<div style={{ height: 400 }} className="loading-screen">
					<i className="fa fa-refresh fa-spin" />
				</div>
			);
		}

		const wrapper = ReactDOM.findDOMNode(this);
		if (wrapper.scrollTop + wrapper.offsetHeight >= wrapper.scrollHeight) {
			clearTimeout(this.state.timeout);
			this.state.timeout = setTimeout(this.scroll, 700);
		}

		if (this.props.messages.length === 0) {
			return (
				<div style={{ height: 400 }} className="text-center">
					{LangJs.chat.noContent}
				</div>
			);
		}

		const items = [];
		if (this.props.stateStore.listLoadingOld) {
			items.push(
				<div key="loading-old" style={{ height: 80 }} className="loading-screen">
					<i className="fa fa-refresh fa-spin" />
				</div>,
			);
		}

		this.props.messages.forEach((value) => {
			const key = `${value.id}-item`;
			items.push(
				<Item
					item={value}
					key={key}
					actions={this.props.actions}
					userId={this.props.stateStore.user.id}
					userName={this.props.stateStore.user.name}
				/>,
			);
		});

		return (
			<div className="chat-list" onScroll={this.handleScroll}>
				{items}
			</div>
		);
	}
}
