import React      from 'react';
import Header     from './Header';
import BaseModule from './Base';
import List       from './List';
import Footer     from './Footer';

export const Chat = () => (
	<div className="chat-box">
		<BaseModule />
		<Header />
		<div className="clearfix" />
		<List />
		<Footer />
	</div>
);

export default Chat;
