if (!global._babelPolyfill && !window._babelPolyfill) {
	require("babel-polyfill");
}
import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import Chat from "./containers/Chat";
import reducer from "./reducers";
import thunk from "redux-thunk";

const store = createStore(reducer, applyMiddleware(thunk));

ReactDOM.render(
	<Provider store={store}>
		<Chat />
	</Provider>,
	document.getElementById("shoutbox-wrapper"),
);
