import * as c from "../constants/Constants";

const initialState = [];

function getIndex(state, id) {
	let i = -1;
	state.forEach((val, index) => {
		if (val.id === id) {
			i = index;
		}
	});
	return i;
}

export default function messages(state = initialState, action) {
	switch (action.type) {
		case c.LOAD_NEW:
			return [...state, ...action.messages];

		case c.LOAD_OLD:
			return [...action.messages, ...state];

		case c.DELETE_ITEM: {
			const i = getIndex(state, action.id);
			if (i !== -1) {
				state.splice(i, 1);
			}
			return [...state];
		}

		case c.BAN_USER: {
			let newState;

			if (isNaN(parseInt(action.userId))) {
				newState = state.filter(item => {
					return item.author.name != action.userId;
				});
			} else {
				newState = state.filter(item => {
					return item.author.id != action.userId;
				});
			}

			return [...newState];
		}

		default:
			return state;
	}
}
